import stepLines from '../images/stepLines.svg';

import { 
	Activity,
	Codesandbox,
	Percent,
	BarChart2,
	Type

} from 'react-feather'

export default function HowIWork() {

    return (
        <section id="howIWork" className="howIWork-section">
			<h3>HERE'S HOW I WORK</h3>

			<div className="step">
				<h4>1</h4>
				<p>I start by <b>studying your data</b>. I will need your help at this stage. After all - it is your data and you know it best. </p>
			</div>
		
			<img src={stepLines} alt="line to connect steps"/>
		
			<div className="step">
				<h4>2</h4>
				<p>Once I understand your data, you can <b>ask me to do anything with it</b>. I'll get you results almost immediately. </p>
			</div>

			<img src={stepLines} alt="line to connect steps"/>

			<div className='two-column-wrapper'>
				<div className="result">
					<Activity id='icon' />
					<h5>PREDICTIONS</h5>
					<p>You want to predict something? Sure - I'll turn your data into a good prediction. Or tell you if I cant. </p>
				</div>
	
				<div className="result">
					<Percent id='icon'/>
					<h5>PROBABILITY</h5>
					<p>You need something more complex like the probability of a hypothesis? I can compute those.</p>
				</div>
			</div>

			<div className="two-column-wrapper">
				<div className="result">
					<Codesandbox id='icon'/>
					<h5>SIMULATIONS</h5>
					<p>Maybe you want me to run some simulations?</p>
				</div>
	
				<div className="result">
					<BarChart2 id='icon'/>
					<h5>COMPARISONS</h5>
					<p>
						Or compare several scenarios and select the most promising one? 
						<br/>Not a problem.
					</p>
				</div>
			</div>
			<div className="result" id="explanation">
				<Type id='icon'/>
				<h5>EXPLANATIONS</h5>
				<p> You may even mistrust your own data. If you suspect that it is biased in some way, let me know. I'll try to get you unbiased answers. And dont worry - I will explain all of my answers.</p>
			</div>

			<div id="sub-tagline">
				<p>
					<b style={{color: '#1459AB'}}>Bottom Line.</b>
					<br/> <br/>
					Ask me a clear question, get an intelligent answer. 
					If I can't help you, I'll say so. And tell you why. 
					<br/>
					Its really a very simple arrangement.
				</p>
			</div>
		</section>
    )
}