import React, { useEffect, useState } from 'react'
import logo from '../images/logo.svg';
import AnchorLink from 'react-anchor-link-smooth-scroll'
import { elastic as Hamburger } from 'react-burger-menu'

// export default function Navigation({handlePageNav}) {
export default function Navigation() {
    const [ shadowVisible, setShadowVisible ]  = useState(false)

    useEffect(() => {
        window.addEventListener("scroll", () => {
            const yOffset = window.pageYOffset;

            yOffset === 0 ? setShadowVisible(false) : setShadowVisible(true)
        })
    })

    const boxShadow = shadowVisible
        ? '0 0.0625rem 0.375rem 0 rgba(0, 0, 0, 0.1)'
        : 'none'

    const height = shadowVisible
        ? '9vh'
        : '15vh'

    return (
        <div className="navbar" style={{boxShadow: boxShadow, height: height}} id="nav">
            <a href="/"><img src={logo} alt="title-logo"/></a>
        
            <nav className="left">
                <Hamburger id="nav_links">
                    <AnchorLink className="menu-item" offset='100' href="#main">Home</AnchorLink>
                    <AnchorLink className="menu-item" offset='100' href="#howIWork">How I Work</AnchorLink>
                    <AnchorLink className="menu-item" offset='100' href="#contact">Contact</AnchorLink>
                </Hamburger>
            </nav>
        
            <nav className="desktop-nav" >
                <AnchorLink id="nav-item" offset='100' href="#main">Home</AnchorLink>
                <AnchorLink id="nav-item" offset='150' href="#howIWork">How I Work</AnchorLink>
                {/* <AnchorLink id="nav-item" offset='100' href="#faq">FAQ</AnchorLink> */}
                <AnchorLink id="nav-item" offset='150' href="#contact">Contact</AnchorLink>
            </nav>    

            {/* <nav className="left">
                <Hamburger id="nav_links">
                    <button className="menu-item" onClick={() => handlePageNav('Home')}>Home</button>
                    <button className="menu-item" onClick={() => handlePageNav('FAQ')}>FAQ</button>
                    <button className="menu-item" onClick={() => handlePageNav('Contact')}>Contact</button>
                </Hamburger>
            </nav>
        
            <nav className="desktop-nav" >
                <button id="nav-item" onClick={() => handlePageNav('Home')}>Home</button>
                <button id="nav-item" onClick={() => handlePageNav('HowIWork')}>How I Work</button>
                <button id="nav-item" onClick={() => handlePageNav('FAQ')}>FAQ</button>
                <button id="nav-item" onClick={() => handlePageNav('Contact')}>Contact</button>
            </nav>         */}
        </div>
    )
}