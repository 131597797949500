import title from '../images/title.svg';
import AnchorLink from 'react-anchor-link-smooth-scroll'

// export default function Footer({handlePageNav}) { 
export default function Footer() { 

    return (
        <footer>
            <div>Copyright © 2020 Maxarth, LLC</div>
            <img src={title} alt="company-logo-text"/>
            <nav>
                <ul>
                    <AnchorLink id="nav-item" offset='100' href="#main">Home</AnchorLink>
                    <AnchorLink id="nav-item" offset='100' href="#howIWork">How I Work</AnchorLink>
                    <AnchorLink id="nav-item" offset='100' href="#contact">Contact</AnchorLink>
                </ul>
            </nav>
        </footer>  
    )
}