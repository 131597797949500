import React from 'react'
import ContactForm from '../ContactForm'
// import { AtSign, Phone } from 'react-feather'

// const BasicInfo = () =>
//     <div id="contact-page-basic-info">
//         <h3>CONTACT</h3>
//         <div id="info-item">
//             <AtSign id="icon" width={19} height={10} viewBox={"0 0 24 24"}/> info@synd.ai
//         </div>
//         <div id="info-item">
//             <Phone id="icon" width={19} height={10} viewBox={"0 0 24 24"}/> (301) 873-1898
//         </div>
//     </div>

const Contact = ({style}) => {
    return (
        <div style={{display: style}} id="contact">

            {/* <BasicInfo/> */}

            <ContactForm 
                title="WANT MORE DETAILS?"
                subtitle="Tell me who to contact."
            />   
        </div>
    )
}

export default Contact