import React from 'react'
import graphLines from '../images/graphLines.svg';
import { 
	Clock,
	Search,
	FastForward
} from 'react-feather'

export default function Introduction() {
    return (
        <section className="intro-section">
			<div id="tagline">
				<h1>From <b style={{color: '#C9C9C9'}}>Data</b> to <b style={{color: '#1459AB'}}>Intelligence</b>.<br/> Without the Fuss.</h1>
				<img src={graphLines} alt="graph lines"/>
			</div>

			<div id="everWishYouCould">
				<h3>EVER WISH YOU COULD...</h3>
				<div className="three-column-wrapper">
					<div className="icon-text-pair">
						<Search id="icon"/>
						<p>Interrogate your <br/> data directly?</p>
					</div>
					<div className="icon-text-pair">
						<Clock id="icon"/>
						<p>Get timely insights <br/> without training models?</p>
					</div>
					<div className="icon-text-pair">
						<FastForward id="icon"/>
						<p>Streamline going <br/>from data to decisions?</p>
					</div>
				</div>
			</div>

			<div id="sub-tagline">
				<p>
					<b>Well, now you can.</b> <br/>
					You ask the questions, I get the answers.
				</p>
			</div>
			
		</section>
    )
}