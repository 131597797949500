import React from 'react'

import Header from '../Header'
import Introduction from '../Introduction'
import HowIWork from '../HowIWork'
// import ContactForm from '../ContactForm';

const Main = ({style}) => {
	 
  return (
	<div style={{display: style}} id="main">
		<main>
			<Header/>
			<Introduction/>			
			<HowIWork />
			{/* <ContactForm 
				title="WANT MORE DETAILS?"
				subtitle="Tell me who to contact."
			/> */}
		</main>
   </div>
 );
}

export default Main;
