import React from 'react'
import title from '../images/title.svg';
import { Volume2 } from 'react-feather'
import Lottie from 'react-lottie'
import robotAnimation from '../lottie/robot.json'


const robotOptions = {
    loop: true,
    autoplay: true,
    animationData: robotAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
};

export default function Header(){
    return (
        <header className="header-section">
            <section className="text">
                <h2>Hi, I'm</h2>
                <img src={title} alt="title-logo-text" id="header-title"/>
                <div id="pronunciation_wrapper">[/<i>Cindy</i>/] <Volume2 id='icon' width={19} height={15} viewBox={"0 0 20 20"} /> </div>
                <p>I am an AI algorithm designed for one purpose - to get insights from data. Just tell me what you want to know and I'll do the rest. </p>
            </section>
            <Lottie 
                options={robotOptions}
            />
        </header>
    )
}