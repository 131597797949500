import React from 'react'
import { useForm } from "react-hook-form";
import _ from "lodash/fp";
import Notification from './Notification'
import { useEffect, useState } from "react";

var AWS = require('aws-sdk/dist/aws-sdk-react-native');

const ContactForm = (props) => {

    const { register, handleSubmit, errors } = useForm();
    const [ errorMessage, setErrorMessage ] = useState('')
    const [ buttonValue, setButtonValue ] = useState('SEND')

    useEffect(() => {
        setTimeout(() => {
            setErrorMessage('')
        }, 500)
    }, [setErrorMessage])

    const onSubmit = (data, e) => {
        setButtonValue('Sending...')

        var contactInfo = {
            "name": data.name,
            "email": data.email,
            "phone": data.phone,
            "company": data.company,
            "message": data.message
        }

        AWS.config.update({
            accessKeyId: process.env.REACT_APP_ACCESS_KEY,
            secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY,
            region: 'us-east-1',
        });

        var lambda = new AWS.Lambda();

        var params = {
            FunctionName: 'sendEmail',
            Payload: JSON.stringify(contactInfo)
        };

        lambda.invoke(params, async(err, data) => {
            if (err) {
                console.log(err, err.stack, 'error')
                setErrorMessage('There was a problem submitting your message. Please get in touch using the email/phone number above.')
            }
            else {
                console.log('Successfull!', data);
                await setErrorMessage('Thank you for your message.')
                e.target.reset()
                setButtonValue('SEND')
            }
        });
    };
      
    const formTitle = props.title
    const formSubTitle = props.subtitle

    return (
        <div id="contact-section">
            <h3> {formTitle} </h3>
            <p id="contact-subtitle"> {formSubTitle}</p>
            <p> or email me directly at <a href="mailto:info@synd.ai">info@synd.ai</a></p>
            <form  onSubmit={handleSubmit(onSubmit)}>
                <div className="form-item">
                    <label>Name <span style={{color: '#1575B4'}}>*</span></label>
                    <input name="name" ref={register({ required: true})} />
                    {_.get("name.type", errors) === "required" && (
                        <p>This field is required.</p>
                    )}
                </div>
                
                <div className="form-item">
                    <label>Email <span style={{color: '#1575B4'}}>*</span></label>
                    <input name="email" ref={register({
                        required: true,
                        pattern: /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g
                    })} />
                    {_.get("email.type", errors) === "required" && (
                        <p>This field is required.</p>
                    )}    
                    {_.get("email.type", errors) === "pattern" && (
                        <p>Please enter a valid email.</p>
                    )}                                
                </div>

                <div className="form-item">
                    <label>Phone</label>
                    <input name="phone" ref={register({ 
                        pattern: /^(\+\d{1,3}\s)?\(?\d{2,4}\)?[\s.-]?\d{2,3}[\s.-]?\d{3,4}$/
                    })}/>
                    {_.get("phone.type", errors) === "pattern" && (
                        <div>
                            <p>
                                Please use one of the following formats:
                                <br/>
                                <span style={{color: '#BFBFBF', fontWeight: '400'}}>
                                    <b>US:</b> (123) 456-7832, 6179398592, 664-555-2342 <br/>
                                    <b>International:</b> +1 2331234234, +91 1222123123, +359 875557748
                                </span>
                            </p>
                        </div>
                    )}                 
                </div>

                <div className="form-item">
                    <label>Company</label>
                    <input name="company" ref={register} />
                </div>
                
                <div className="form-item">
                    <label>Message</label>
                    <textarea rows="7" cols="1" wrap="soft" name="message" ref={register} />
                </div>
                <Notification message={errorMessage}/>        
                <input 
                    className="form-item"
                    type="submit"
                    value={buttonValue}
                />
            </form>
        </div>
    )
}

export default ContactForm