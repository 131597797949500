import './App.css';
import React from 'react'

import Navigation from './components/Navigation'
import Main from './components/pages/main'
// import FAQ from './components/pages/faq'
import Contact from './components/pages/contact'
import Footer from './components/Footer'

function App() {
  // const [homeStyles, setHomeStyles] = useState('block')
  // const [faqStyles, setFaqStyles] = useState('none')
  // const [contactStyles, setContactStyles] = useState('none')


  // const handlePageNav = (page) => {
  //   console.log('page clicked: ', page)


  //   if(page === 'HowIWork') {
  //     setHomeStyles('block')
  //     setFaqStyles('none')
  //     setContactStyles('none')
      
  //     if (window.matchMedia("(min-width: 1300px)").matches) {
  //       console.log('Desktop Large View')
  //       window.scrollTo({
  //         top:2600,
  //         left: 0,
  //         behavior: 'smooth'
  //       })
  //     }

  //     else if (window.matchMedia("(min-width: 1024px)").matches) {
  //       console.log('Desktop Medium View')
  //       window.scrollTo({
  //         top:2300,
  //         left: 0,
  //         behavior: 'smooth'
  //       })
  //     }

  //     else if (window.matchMedia("(min-width: 768px)").matches) {
  //       console.log('Tablet View')

  //       window.scrollTo({
  //         top:2000,
  //         left: 0,
  //         behavior: 'smooth'
  //       })
  //     }


  //   } 
    
  //   else {
  //     page === 'Home' ? setHomeStyles('block') : setHomeStyles('none')
  //     page === 'FAQ' ? setFaqStyles('block') : setFaqStyles('none')
  //     page === 'Contact' ? setContactStyles('block') : setContactStyles('none')

  //     window.scrollTo({
  //       top: 0,
  //       left: 0,
  //       behavior: 'smooth'
  //     })
  //   }

  // }

  return (
    <div>
      {/* <Navigation handlePageNav={handlePageNav}/>   */}
      <Navigation/>
      <div id="body-wrapper">
        <Main/>
        {/* <FAQ/> */}
        <Contact/>
        <Footer/>  

        {/* <Main style={homeStyles}/>
        <FAQ style={faqStyles} />
        <Contact style={contactStyles}/>
        <Footer handlePageNav={handlePageNav}/>   */}
      </div>
    </div>
 );
}

export default App;
